import { useState, useEffect } from "react"

export const useIntersection = (
  element,
  rootMargin,
  onlyFirstIntersection = true
) => {
  const [isVisible, setState] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setState(entry.isIntersecting)
          if (onlyFirstIntersection && element.current) {
            observer.unobserve(element.current)
          }
        }
      },
      { rootMargin }
    )

    element.current && observer.observe(element.current)

    return () => element.current && observer.unobserve(element.current)
  }, [])

  return isVisible
}
