import { useEffect, useRef } from "react"

import { isTestMode } from "helpers/application"

const CLSMonitor = () => {
  const clsRef = useRef(0)

  // https://web.dev/articles/debug-layout-shifts#usage
  useEffect(() => {
    if (!isTestMode && typeof PerformanceObserver !== "undefined") {
      const observer = new PerformanceObserver((entryList) => {
        for (const entry of entryList.getEntries()) {
          if (!entry.hadRecentInput) {
            clsRef.current += entry.value
            console.log("Current CLS value:", clsRef.current, entry)
          }
        }
      })

      observer && observer.observe({ buffered: true, type: "layout-shift" })

      return () => observer && observer.disconnect()
    }
  }, [])

  return null
}

export default CLSMonitor
