import { isTestMode } from "helpers/application"

const toggleHelpScout = () => {
  const Beacon = window.Beacon
  if (!Beacon) return null
  Beacon("open")
  // Beacon("navigate", "/ask/chat")

  dataLayer.push({
    event: "clicked-live-chat",
  })
}

export const isHelpScout = () => {
  if (typeof window === "undefined") return false
  return window.Beacon !== "undefined"
}

export const handleLiveChat = () => {
  const Beacon = window.Beacon
  if (!Beacon && !isTestMode) {
    dataLayer.push({
      event: "load-helpscout-script",
    })
  }
  if (isHelpScout()) return toggleHelpScout()
}
